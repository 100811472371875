import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStoreCtx } from "../../store";
import { KION_TARIFF_PERIOD } from "../../util/interfaces";
import { KION_PICTURE_TYPE, Subscription } from "../../util/promo";
import { PaymentConfirm } from "./payment-confirm/payment-confirm";
import { PaymentInit } from "./payment-init/payment-init";

export enum PaymentStep {
    Init,
    Phone,
    Card,
    Error,
    Success
}

export const SubscriptionOffer = observer(() => {
    const httpService = useStoreCtx().appStore;

    const selectedSubscription: Subscription = httpService.selectedSubscription

    const [step, setStep] = useState<PaymentStep>(PaymentStep.Init)

    function isSelectedPaymentMethod() {
        return [PaymentStep.Phone, PaymentStep.Card].indexOf(step) !== -1
    }
  
    return (
        <div style={{
            background: '#090B3F',
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            {step === PaymentStep.Init && (
                <PaymentInit 
                    subscription={selectedSubscription}
                    onNext={(event) => setStep(event)} 
                />
            )}
         
            {isSelectedPaymentMethod() && (
                <PaymentConfirm
                    paymentMethod={step}
                    subscription={selectedSubscription}
                    onNext={(event) => setStep(event)} 
                />
            )}
       </div>
    )
})