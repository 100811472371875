import { KION_TARIFF_PERIOD, KionPromoPrice, KionPromoPriceFragment } from './interfaces'
import { transcriptRuEn } from './helpers'

export enum KION_PICTURE_TYPE {
  SMALL,
  BIG
}

export interface HardcodedSubscription {
  name: string,
  description: string,
  transcription: string,
  promoCode?: string,
  image: string | null,
  ageRating: number,
  isBigPicture: KION_PICTURE_TYPE,
}

export interface Subscription extends HardcodedSubscription, KionPromoPriceFragment {
}

export const PROMO: { [key: string]: Omit<HardcodedSubscription, 'transcription'> } = {
  "cinema_light_svod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "Кино Lite",
    "description": "Познакомьтесь с Kion. Подборка хорошего кино для вас",
    "image": "202012141333035414js.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "Весь МТС. KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210419203636686alk.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "Весь МТС. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "202104192034581302ct.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "livetv_movie_v4_live_per_month_mts_ott_sdhd_b2c_08_2019": {
    "name": "НАСТРОЙ КИНО!",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "2022071222230060697m.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "livetv_match_v4_live_per_month_mts_ott_sdhd_b2c_08_2019": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе",
    "image": "202104191939565530v8.png",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "livetv_amedia_live_per_month_mts_ott_sdhd_b2c_v1_05_2020": {
    "name": "Амедиа Премиум HD",
    "description": "ТВ-каналы с зарубежными сериалами и фильмами",
    "image": "20220628102228327h0q.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "livetv_sport_v1_live_per_month_mts_ott_sdhd_b2c_10_2019": {
    "name": "Спортивный",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "livetv_ocean_live_per_month_mts_ott_sdhd_b2c__v1_03_2020": {
    "name": "Океан открытий",
    "description": "Пакет каналов от создателей Discovery Channel",
    "image": "202104191947115380h7.png",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "kion_ozon_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2023": {
    "name": "KION + OZON Premium",
    "description": "2 в 1: сериалы KION, фильмы, ТВ и OZON Premium",
    "image": "20230226014549626tgj.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_football_live_per_month_mts_ott_sdhd_b2c__v1_07_2020": {
    "name": "МАТЧ! ФУТБОЛ",
    "description": "ВСЕ о футболе в HD-качестве",
    "image": "20220720083018337fwf.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "start_svod_v4_svod_per_month_mts_ott_sdhd4k_b2c_08_2019": {
    "name": "START",
    "description": "Подписка на START для Kion",
    "image": "2021041920090810521m.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "ivi_svod_svod_per_month_mts_ott_sdhd4k_b2c_v5_05_2020": {
    "name": "Иви лайт",
    "description": "Специальная подписка IVI Лайт для Kion",
    "image": "20210419200720445pyh.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "start_svod_prizes_v4_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "START prizes",
    "description": "Подписка на START для Kion",
    "image": "2021041920232654325c.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "start_all_mts_svod_per_month_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "Весь МТС. START",
    "description": "\"Подписка на START в тарифе \"\"Весь МТС\"\"\"",
    "image": "20210419202741537irq.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "amediateka_svod_prizes_v1_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "AMEDIATEKA prizes",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "202204121438340260yw.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "amediateka_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "Весь МТС. Amediateka",
    "description": "\"Самые ожидаемые сериалы планеты в тарифе \"\"Весь МТС\"\"\"",
    "image": "20220412143916291tii.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "childhood_svod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "Детство",
    "description": "Любимые мультфильмы и промокод на скидку от сервиса «Где мои дети» внутри.",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "adult_svod_per_month_mts_ott_sdhd4k_b2c_v_1_05_2020": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит. Скидка 30% внутри на shop.mts.ru",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "amediateka_svod_v1_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "AMEDIATEKA",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "202204121440077803l3.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "a_lot_of_tv_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210422091735418qa3.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210816130305638zoo.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210422091735418qa3.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "2021042209195652915g.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "a_lot_of_tv_prizes_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION 2 мес. в подарок",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 195+ ТВ-каналов",
    "image": "2022041517134906005r.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_prizes_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION prizes",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "2021041920550164842i.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "KION Light для Premium",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210419225648113boc.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_for_smart_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "ТВ для Своих",
    "description": "Более 150 ТВ-каналов, тысячи фильмов, сериалов и мультиков",
    "image": "20201214133851413g0l.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "НЕТАРИФ KION Light",
    "description": "\"Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов для абонентов \"\"НЕТАРИФА\"\"!\"",
    "image": "20210419210330394wo6.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_11_2020": {
    "name": "НЕТАРИФ KION",
    "description": "\"Каналы, фильмы, сериалы и мультфильмы для абонентов \"\"НЕТАРИФА\"\"!\"",
    "image": "20210419210151550ty9.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION ТОП light",
    "description": "Смотри 3 онлайн-кинотеатра: KION, IVI Лайт, START и 200+ ТВ-каналов в одной подписке",
    "image": "20210422090640434vw5.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v2_03_2021": {
    "name": "KION ТОП",
    "description": "Выгода 50%. Смотри в одной подписке: KION, AMEDIATEKA, START, IVI Лайт и более 190 ТВ-каналов.",
    "image": "20220422123316322iz3.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_bvod_per_day_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "2021042209195652915g.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "super_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "Весь МТС. Полный дом. KION Light",
    "description": "Более 100 ТВ-каналов и тысячи фильмов, сериалов и мультфильмов",
    "image": "202104192112231048k5.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION Light",
    "description": "Попробуй бесплатно: тысячи фильмов, сериалов, мультфильмов и 130+ ТВ-каналов.",
    "image": "20220324160539185e7z.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "KION Light",
    "description": "Попробуй бесплатно: тысячи фильмов, сериалов, мультфильмов и 130+ ТВ-каналов.",
    "image": "20220324160539185e7z.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_bvod_per_year_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "2021042209195652915g.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_bvod_per_month_mts_ott_sdhd4k_b2c_v2_04_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "2021042209195652915g.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "Продажа приставки. KION на 3 месяца",
    "description": "Приставка МТС и 3 месяца подписки KION",
    "image": "20210521114919724os1.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_router_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "Комплект приставка + роутер",
    "description": "Приставка МТС ТВ, Роутер",
    "image": "202105211151047230my.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_ott_svod_per_year_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "Продажа приставки. KION на 12 месяцев",
    "description": "Приставка МТС и 12 месяцев подписки KION",
    "image": "20210521114604725e0k.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_for_nb_bvod_per_day_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "ТВ для ноутбука",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20210521113607509m7o.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "adult_svod_per_month_mts_ott_sdhd4k_b2c_v_1_05_2021": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит (18+). Скидка внутри на shop.mts.ru",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "KION Light",
    "description": "Попробуй бесплатно: тысячи фильмов, сериалов, мультфильмов и 130+ ТВ-каналов.",
    "image": "20220324160539185e7z.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_we_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2021": {
    "name": "МЫ МТС. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20210713134147459mfs.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_corp_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2021": {
    "name": "CORP. KION Light",
    "description": "Тысячи фильмов, сериалов, мультфильмов и 130+ ТВ-каналов",
    "image": "20210728085725682qqm.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kionlight_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "Весь МТС. KION Light",
    "description": "\"\"\"Супер\"\" + фильмы и сериалы студий Sony и Universal и 180+ ТВ-каналов\"",
    "image": "20210818115800623iw1.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_all_mts_super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "Весь МТС Супер. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20210818120600621dqk.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_we_mts_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "МЫ МТС+. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20210818120900621j1k.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_all_mts_extra_bvod_per_month_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "Весь МТС Экстра. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20210909102658344rtr.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_12m_bvod_per_year_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "KION 12 месяцев",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 180+ ТВ-каналов.",
    "image": "202108271159283165ei.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20210827114523352qzx.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "НЕТАРИФ KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20210909103113321lj2.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kionlight_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_09_2021": {
    "name": "KION Light 3 месяца",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 140+ ТВ-каналов.",
    "image": "20210910102259200mqn.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kionlight_12m_bvod_per_year_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "KION Light 12 месяцев",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 200+ ТВ-каналов.",
    "image": "20210910101924269k4e.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "KION 2 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "202109171200044002ih.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_08_2021": {
    "name": "KION 3 месяца",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 200+ ТВ-каналов.",
    "image": "20210827115208330j34.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_6m_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v2_08_2021": {
    "name": "KION 6 месяцев",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 200+ ТВ-каналов.",
    "image": "20210827115538357b72.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v2_09_2021": {
    "name": "KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "2021092013084804425t.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v3_09_2021": {
    "name": "KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "2021092013084804425t.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_junior_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_10_2021": {
    "name": "Junior. KION",
    "description": "Детские ТВ-каналы, мультфильмы, кино и сериалы",
    "image": "20211111111345450wh8.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "test_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "Тест. KION 3 месяца",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 180+ ТВ-каналов.",
    "image": "20211102095519155jip.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_movie_live_per_month_mts_ott_sdhd_b2c_v1_11_2021": {
    "name": "НАСТРОЙ КИНО!",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "2022071222230060697m.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "kion_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION 12 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20210827114523352qzx.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_match_live_per_month_mts_ott_sdhd_b2c_v1_11_2021": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе! После промо - 299 руб./мес.",
    "image": "20220706222422449rxy.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "match_we_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "МЫ МТС. Матч Премьер. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ тв-каналов, в том числе Матч Премьер",
    "image": "202112230155330332j1.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "match_we_mts_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "МЫ МТС+. Матч Премьер. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ тв-каналов, в том числе Матч Премьер",
    "image": "202112230156241985ys.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v3_12_2021": {
    "name": "KION ТОП",
    "description": "Выгода 58%. В одной подписке: KION, AMEDIATEKA, IVI Лайт, START и 210 ТВ-каналов.",
    "image": "20211216010053074u4t.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_ozon_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION OZON",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов.",
    "image": "20220422123408712nff.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kionlight_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION Light для Premium",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20210419225648113boc.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "start_svod_per_month_mts_ott_sdhd4k_b2c_v5_12_2021": {
    "name": "START",
    "description": "Подписка на START для Kion",
    "image": "2021041920090810521m.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v4_12_2021": {
    "name": "KION ТОП",
    "description": "Смотри 4 онлайн-кинотеатра: KION, IVI Лайт, AMEDIATEKA, START и 210 тв-каналов в одной подписке по спец цене",
    "image": "202112271048530035mh.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "test_product_svod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "Тестирование продуктов KION",
    "description": "Тестирование продуктов",
    "image": "20211224130949155588.png",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "kion_2m_bvod_per_multimonth2_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "KION 2 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20220217140235179eov.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "mobile_router_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа мобильного роутера",
    "description": "Роутер, ТП для ноутбука, подписка KION",
    "image": "20220225150655166v2m.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "cpe_router_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа CPE роутера",
    "description": "Роутер, ТП для ноутбука, подписка KION",
    "image": "20220225150914155ok7.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "modem_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа модема",
    "description": "Модем, ТП для ноутбука, подписка KION",
    "image": "20220225151150780lod.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "panel_antenna_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа панельной антенны",
    "description": "Панельная антенна, ТП для ноутбука, подписка KION",
    "image": "20220225151409842tzt.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа приставки 1000",
    "description": "Приставка и 3 месяца подписки KION",
    "image": "20220228113500157hke.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_1m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20220317141754873q2z.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_autopay_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION + Автоплатеж",
    "description": "Скидка 20% абонентам «Тарифище» и «Автоплатёжа» на подписку с сериалами KION, фильмами, ТВ",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION 3 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20221206171929536icj.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION с выгодой до 20%",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20210827114523352qzx.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_match_mh_live_per_month_mts_ott_sdhd_b2c_v1_04_2022": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе! После промо - 299 руб./мес.",
    "image": "20220706222422449rxy.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "childhood_svod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "Детство",
    "description": "Мультфильмы от KION и подписка на сервис «Где мои дети» по промокоду KIONDETI",
    "image": "20220524121508287rvv.jpg",
    "ageRating": 0,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_149_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_149_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_199_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_199_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "match_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "НЕТАРИФ МАТЧ ПРЕМЬЕР",
    "description": "МАТЧ ПРЕМЬЕР + более 195 ТВ-каналов, тысячи фильмов и сериалов",
    "image": "202206141241473023l4.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_amedia_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2022": {
    "name": "KION + AMEDIATEKA",
    "description": "Оригинальные сериалы KION, тысячи фильмов, сериалы AMEDIATEKA, 200+ ТВ-каналов",
    "image": "20220623154507402lyf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_ivi_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2022": {
    "name": "KION + IVI Лайт",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мултьфильмы, контент IVI, 200+ ТВ-каналов",
    "image": "20220623154847402dx9.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_for_nb_bvod_per_day_mts_ott_sdhd4k_b2c_v1_06_2022": {
    "name": "ТВ для ноутбука",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20210521113607509m7o.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_for_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2022": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "2022051912244330017e.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_for_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2022": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "2022051912244330017e.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_stb_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "KION 12 месяцев",
    "description": "Бесплатный доступ к фильмам и сериалам, 200+ ТВ-каналов, привилегии МТС Premium",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_mts_junior_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "МТС Junior. KION",
    "description": "Детские ТВ-каналы, мультфильмы, кино и сериалы",
    "image": "2022081211004329641y.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_cashback_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ тв-каналов",
    "image": "20220824134617311e2z.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_year_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "Продажа приставки OTT",
    "description": "Продажа приставки OTT",
    "image": "20220228113500157hke.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_for_nb_mini_bvod_per_day_mts_ott_sdhd4k_b2c_v1_10_2022": {
    "name": "ТВ для ноутбука мини",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20210521113607509m7o.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_year_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION: до 43% выгода",
    "description": "Подписка на 3, 6, 12 мес: 200+ ТВ каналов, фильмы, сериалы, МТС Premium. Выбирайте номинал внутри",
    "image": "20220520091337332ggc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_payment_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "20221208110637972bxn.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA",
    "description": "Оригинальные сериалы KION, тысячи фильмов, сериалы AMEDIATEKA, 200+ ТВ-каналов",
    "image": "20220623154507402lyf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA",
    "description": "Оригинальные сериалы KION, тысячи фильмов, сериалы AMEDIATEKA, 200+ ТВ-каналов",
    "image": "20220623154507402lyf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA",
    "description": "Оригинальные сериалы KION, тысячи фильмов, сериалы AMEDIATEKA, 200+ ТВ-каналов",
    "image": "20220623154507402lyf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_ozon_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION 2023 часа",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, мультфильмов",
    "image": "20221213024359576tog.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  }
}

function createSubscriptionObject({
                                    productId,
                                    discountPrice,
                                    price,
                                    promotion,
                                    tariffPeriod
                                  }: KionPromoPrice): Subscription | undefined {
  if (!PROMO[productId]) {
    return undefined
  }
  return {
    productId,
    discountPrice,
    price,
    promotion,
    tariffPeriod, ...PROMO[productId],
    transcription: transcriptRuEn(PROMO[productId].name)
  }
}

function splitPromo(filteredPromo: Subscription[]): [Subscription[], Subscription[]] {
  const big: Subscription[] = []
  const small: Subscription[] = []

  filteredPromo.forEach(item => item.isBigPicture === KION_PICTURE_TYPE.BIG ? big.push(item) : small.push(item))

  return [big, small]
}

export function joinPromoData(promos: KionPromoPrice[]): [Subscription[], Subscription[]] {
  const subscriptions = promos.map(createSubscriptionObject).filter((val: Subscription | undefined) => val !== undefined) as Subscription[]
  return splitPromo(subscriptions)
}

export function joinPromoDataDebug(): [Subscription[], Subscription[]] {
  const fakePromo = Object.keys(PROMO).map<KionPromoPriceFragment>(key => {
    return {
      productId: key,
      discountPrice: 29900,
      promotion: {timeToLiveValue: 1},
      price: 59900,
      tariffPeriod: KION_TARIFF_PERIOD.MONTHLY
    }
  }) as KionPromoPrice[]
  return splitPromo(fakePromo.map(createSubscriptionObject) as Subscription[])
}
